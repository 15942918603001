/*
 * @Copyright © 2016-2021 Isaac Marotte - All Rights Reserved
 *
 * This file is the sole property of its owner.
 * Unauthorized use of this file, via any medium or form, whole or in part,
 * is strictly prohibited without the expressed written permission of Isaac Marotte
 *
 * This file is proprietary and confidential
 *
 * Last Modified: 2021.1.28
 */
export const canvasWidth = 500;
export const canvasHeight = canvasWidth + 200;
export const mainRadius = 470 / 2;
const radius = 22;

export const mainCircleX = canvasWidth / 2;
export const mainCircleY = mainCircleX + 10;

const point1x = 28;
const point1y = 330;

const point2x = canvasWidth - point1x;

const point3x = canvasWidth / 2;
const point3y = 28;

const point4y = canvasWidth - 10;
const point5y = point4y + 150;


export const pointsTriangle = `${point1x}, ${point1y} ${point2x}, ${point1y} ${point3x},${point3y} ${point1x}, ${point1y}`; // eslint-disable-line max-len
export const pointsLine = `${point3x}, ${point4y} ${point3x}, ${point5y} `;


export const circleData = [
  // 9:00
  {
    x: point1x,
    y: point1y,
    r: radius
  },
  // 12:00
  {
    x: point3x,
    y: point3y,
    r: radius
  },
  {
    x: point2x,
    y: point1y,
    r: radius
  },
  // 6:00
  {
    x: point3x,
    y: point4y,
    r: radius
  },
  {
    x: point3x,
    y: point5y,
    r: radius
  }
];

export const viewBoxData = `0 0 ${canvasWidth} ${canvasWidth + 165}`;
