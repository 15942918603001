/*
 * @Copyright © 2016-2021 Isaac Marotte - All Rights Reserved
 *
 * This file is the sole property of its owner.
 * Unauthorized use of this file, via any medium or form, whole or in part,
 * is strictly prohibited without the expressed written permission of Isaac Marotte
 *
 * This file is proprietary and confidential
 *
 * Last Modified: 2021.1.25
 */

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

if ('serviceWorker' in navigator) {
  if (__DEVELOPMENT__) {
    console.log('attempt to register serviceWorker.js'); // eslint-disable-line no-console
  }
  navigator.serviceWorker
    .register('serviceWorker.js')
    .then((reg) => {
      reg.update().catch((e) => { console.error(e); }); // is this required?
      setInterval(() => {
        // manual updates
        // https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#manual_updates
        reg.update().catch((e) => { console.error(e); });
      },
      86400000 // 24 hours
      );
    }).catch((e) => { console.error(e); });
} else {
  console.error('service workers not supported');
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
