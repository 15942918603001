/*
 * @Copyright © 2016-2021 Isaac Marotte - All Rights Reserved
 *
 * This file is the sole property of its owner.
 * Unauthorized use of this file, via any medium or form, whole or in part,
 * is strictly prohibited without the expressed written permission of Isaac Marotte
 *
 * This file is proprietary and confidential
 *
 * Last Modified: 2021.1.25
 */

import howler from 'howler';

/**
 * @namespace playlist
 *
 * @property {function} init
 * @property {string} name
 * @property {Howl} stream
 */

/**
 *
 * @param {string} name
 * @param {number} totalChunks
 * @param {function} onend
 * @return {playlist[]}
 */
export const createPlaylist = (name, totalChunks = 3, onload, onend) => {
  /** @type playlist[] */
  const streams = Array(totalChunks);

  for (let i = 1; i <= totalChunks; i += 1) {
    streams[i] = {
      name: `${name}.act${i}`,
      isValid() {
        if (streams[i].stream) {
          return true;
        }
        console.error(`invalid stream ${this.name}`);
        return false;
      },
      /**
       * @memberOf playlist
       * @instance playlist
       * @param {number} time
       */
      play(time) {
        if (!this.isValid()) { return; }

        if (time) {
          this.stream.seek(time);
        }

        // console.log(`${this.name} play at ${time || 0}s`);

        this.stream.play();
      },
      /**
       * @memberOf playlist
       * @instance playlist
       */
      pause() {
        if (!this.isValid() || !this.stream.playing()) { return; }
        // console.log(`${this.name} pause`);
        this.stream.pause();
      },
      /**
       * @memberOf playlist
       * @instance playlist
       */
      getTime() {
        if (!this.isValid()) { return null; }
        return this.stream.seek();
      },
      /**
       * @memberOf playlist
       * @instance playlist
       */
      duration() {
        if (!this.isValid()) { return 0; }
        return this.stream.duration();
      },

      /**
       * @memberOf playlist
       * @instance playlist
       * @return {null|string}
       */
      state() {
        if (!this.isValid()) { return null; }
        return this.stream.state();
      },
      /**
       * @memberOf playlist
       * @instance playlist
       * @return {boolean}
       */
      isLoaded() {
        return this.state() === 'loaded';
      }
    };
    streams[i].init = (onSuccess) => {
      if (streams[i].stream === undefined) {
        streams[i].stream = new howler.Howl({
          src: __USE_SMALL__
            ? [
              `assets/audio/${name}.act${i}.mp3`
            ]
            : [
              `assets/audio/${name}.act${i}.webm`,
              `assets/audio/${name}.act${i}.mp3`
            ],
          autoUnlock: true,
          preload: true,
          html5: true, // needed for streaming
          html5PoolSize: 20,
          onend,
          onload: () => {
            console.log(`${streams[i].name} loaded`); // eslint-disable-line no-console
            onload(i);
          },
          onloaderror(e) {
            console.error(name);
            console.error(e);
          },
          onplayerror(e) {
            console.error(name);
            console.error(e);
          }
        });
        onSuccess();
      }
    };
  }

  return streams;
};
