/*
 * @Copyright © 2016-2021 Isaac Marotte - All Rights Reserved
 *
 * This file is the sole property of its owner.
 * Unauthorized use of this file, via any medium or form, whole or in part,
 * is strictly prohibited without the expressed written permission of Isaac Marotte
 *
 * This file is proprietary and confidential
 *
 * Last Modified: 2021.1.28
 */

import React, { useEffect, useState } from 'react';
import styles from './app.scss';
import { Player } from './player/Player';
import {
  circleData,
  canvasWidth,
  pointsTriangle,
  pointsLine,
  mainRadius,
  mainCircleX,
  mainCircleY,
  viewBoxData, canvasHeight
} from './geometry.const';


const speakers = [
  __USE_SMALL__
    ? {
      title: 'small',
      track: 'small'
    }
    : {
      title: 'Richard Brake',
      track: 'RichardBrake'
    },
  {
    title: 'Jeff Steitzer',
    track: 'JeffSteitzer'
  },
  {
    title: 'Jen Taylor',
    track: 'JenTaylor'
  },
  {
    title: 'Tommie Earl Jenkins',
    track: 'TommieEarlJenkins'
  }
  // {
  //   title: 'Ensemble',
  //   track: 'ensemble'
  // }
];

const introCircleData = [
  {
    ...circleData[0],
    className: styles.speaker1
  },
  {
    ...circleData[1],
    className: styles.speaker2
  },
  {
    ...circleData[2],
    className: styles.speaker3
  },
  {
    ...circleData[3],
    className: styles.speaker4
  },
  {
    ...circleData[4],
    className: styles.speaker5
  }
];


const App = () => {
  const [displayIntro, setDisplayIntro] = useState(__DISPLAY_ANIMATION__);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      '  ________            ____            _ __  _                 \n'
      + ' /_  __/ /_  ___     /  _/___ _____  (_) /_(_)___  ____     _ \n'
      + '  / / / __ \\/ _ \\    / // __ `/ __ \\/ / __/ / __ \\/ __ \\   (_)\n'
      + ' / / / / / /  __/  _/ // /_/ / / / / / /_/ / /_/ / / / /  _   \n'
      + '/_/ /_/ /_/\\___/  /___/\\__, /_/ /_/_/\\__/_/\\____/_/ /_/  (_)  \n'
      + '    ____             _/____/                                  \n'
      + '   / __ \\___  ____ _/ /___ ___                                \n'
      + '  / /_/ / _ \\/ __ `/ / __ `__ \\                               \n'
      + ' / _, _/  __/ /_/ / / / / / / /                               \n'
      + '/_/ |_|\\___/\\__,_/_/_/ /_/_/_/__                              \n'
      + '            ____/ /____(_) __/ /____  _____                   \n'
      + '           / __  / ___/ / /_/ __/ _ \\/ ___/                   \n'
      + '          / /_/ / /  / / __/ /_/  __/ /                       \n'
      + '          \\__,_/_/  /_/_/  \\__/\\___/_/                        \n\n'
    + `Version: ${__VERSION__}\n\n`);
    let t = setTimeout(() => {
      setDisplayIntro(false);
      t = undefined;
    }, 14000);
    return () => {
      if (t) {
        clearTimeout(t);
      }
    };
  }, []);

  return (
    <div className={styles.main}>
      <header>
        <nav>
          <ul>
            <li>
              <a href={__BASE_URL__}>Home</a>
            </li>
            <li>
              <a href={`${__BASE_URL__}/about`}>About</a>
            </li>
            <li>
              <a href={`${__BASE_URL__}/the-ignition-shop`}>Shop</a>
            </li>
            <li>
              <a href={`${__BASE_URL__}/team`}>Team</a>
            </li>
            <li>
              <a href={`${__BASE_URL__}/contact`}>Contact</a>
            </li>
          </ul>
        </nav>
      </header>
      {
        displayIntro
          ? (
            <svg
              width={canvasWidth}
              height={canvasHeight}
              className={styles.logo}
              viewBox={viewBoxData}
            >
              <circle cx={mainCircleX} cy={mainCircleY} r={mainRadius} />
              <polyline points={pointsTriangle} />
              <polyline points={pointsLine} />
              {
                introCircleData.map(({ x, y, r, className }, index) => {
                  return (
                    <circle key={`${x},${y}`} cx={x} cy={y} r={r} className={className} />
                  );
                })
              }

              <text x="50%" y="50%">
                REALMDRIFTER
              </text>
              Sorry, your browser does not support inline SVG.
            </svg>
          )
          : <Player speakers={speakers} />
      }
    </div>
  );
};

App.propTypes = {};
App.defaultProps = {};

export default App;
