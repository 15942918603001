/*
 * @Copyright © 2016-2021 Isaac Marotte - All Rights Reserved
 *
 * This file is the sole property of its owner.
 * Unauthorized use of this file, via any medium or form, whole or in part,
 * is strictly prohibited without the expressed written permission of Isaac Marotte
 *
 * This file is proprietary and confidential
 *
 * Last Modified: 2021.1.14
 */

import React from 'react';
import PropTypes from 'prop-types';
import styles from './timeDisplay.scss';

const TimeDisplay = ({ time }) => {
  let t = time;
  const hour = Math.floor(t / 3600);
  t -= (hour * 3600);
  const minute = Math.floor(t / 60);
  t -= (minute * 60);
  const seconds = Math.floor(t);
  t -= seconds;
  return (
    <div className={styles.display}>
      {hour}
      <span>:</span>{minute < 10 ? `0${minute}` : minute}
      <span>:</span>{seconds}
      <span>.</span>{Math.floor(t * 10)}
    </div>
  );
};

TimeDisplay.propTypes = {
  time: PropTypes.number
};
TimeDisplay.defaultProps = {
  time: 0
};

export {
  TimeDisplay
};
