/*
 * @Copyright © 2016-2021 Isaac Marotte - All Rights Reserved
 *
 * This file is the sole property of its owner.
 * Unauthorized use of this file, via any medium or form, whole or in part,
 * is strictly prohibited without the expressed written permission of Isaac Marotte
 *
 * This file is proprietary and confidential
 *
 * Last Modified: 2021.1.28
 */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPlaylist } from '../utils/hooks';
import { TimeDisplay } from '../TimeDisplay';
import styles from './player.scss';
import {
  circleData,
  canvasWidth,
  mainRadius,
  mainCircleX,
  mainCircleY,
  pointsLine,
  pointsTriangle,
  viewBoxData,
  canvasHeight
} from '../geometry.const';

const MAX_CHUNKS = 3;
const INITIAL_CHUNK = 1;


const pauseBar1X = 242;
const pauseBar1Y = 375;
const pauseBar2X = pauseBar1X + 10;

const playPoint1 = `240,${pauseBar1Y}`;
const playPoint2 = `240,${pauseBar1Y + 24}`;
const playPoint3 = `262,${pauseBar1Y + 12}`;


const chapterInfo = {
  1: {
    name: 'Act I',
    duration: 10
  },
  2: {
    name: 'Act II',
    duration: 10
  },
  3: {
    name: 'Act III',
    duration: 10
  },
  4: {
    name: 'Epilogue',
    duration: 10
  }
};


const formatTime = (time) => {
  let t = time;
  let hour;
  let strTime = '';
  if (t > 3600) {
    hour = Math.floor(t / 3600);
    t -= (hour * 3600);
    strTime = hour === 0 ? '' : `${hour}:`;
  }
  let minute = 0;
  if (t > 60) {
    minute = Math.floor(t / 60);
    t -= (minute * 60);
    if (hour) {
      strTime = `${strTime}${minute < 10 ? `0${minute}` : minute}`;
    } else {
      strTime = minute.toString();
    }
  } else if (hour) {
    strTime = `${strTime}00`;
  } else {
    strTime = '0';
  }

  const seconds = Math.floor(t);
  return `${strTime}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

const Player = ({ speakers }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [speakerIndex, setSpeakerIndex] = useState(/** @type number */ undefined);
  const [chapter, setChapter] = useState(INITIAL_CHUNK);
  const [timeOffset, setTimeOffset] = useState(0);
  const [time, setTime] = useState(0);
  const [streams, setStreams] = useState(/** @key Object<playlist[]>*/ undefined);
  const [textOpacity, setTextOpacity] = useState(0);
  const [displayName, setDisplayName] = useState('');
  const [currentStream, setCurrentStream] = useState(/** @type playlist */undefined);
  const [isBuffering, setIsBuffering] = useState(true);
  const [loadCount, setLoadCount] = useState(0);

  /**
   *
   * @type {function(Object<playlist[]>, number): void}
   */
  const loadChapter = useCallback((streamArray, id) => {
    console.log(`Attempt to load chapter ${id}`); // eslint-disable-line no-console
    Object.keys(streamArray).forEach((key) => {
      streamArray[key][id].init(() => {
        setLoadCount((prevState) => (prevState + 1));
      });
    });
  }, []);

  /**
   * stream management
   */
  useEffect(async () => {
    const s = {};
    await Promise.all(speakers.map(async (speaker, index) => {
      s[index] = createPlaylist(speaker.track, 3,
        (id) => {
          if (index === 0) {
            // update duration to the correct thing
            chapterInfo[id].duration = s[index][id].duration();
          }

          setLoadCount((prevState) => {
            const newPrevState = prevState - 1;
            if (newPrevState < 0) {
              console.error('invalid loadCount', newPrevState); // eslint-disable-line no-console
              return 0;
            }
            return newPrevState;
          });
        },
        () => {
          setChapter((prevState) => {
            if (prevState === MAX_CHUNKS) {
              setTime(0);
              setSpeakerIndex(undefined);
              return INITIAL_CHUNK;
            }
            console.log('next chapter'); // eslint-disable-line no-console
            return prevState + 1;
          });
        });
    }));

    loadChapter(s, INITIAL_CHUNK);

    setStreams(s);
  }, [speakers]);


  /**
   * preloading
   *
   * this will result in it attempting to load chapters twice. This is okay. We only load once
   */
  useEffect(() => {
    if (streams === undefined) return;

    // load the next stream
    const nextChunk = chapter + 1;
    if (loadCount === 0 && nextChunk <= MAX_CHUNKS) {
      loadChapter(streams, nextChunk);
    }
  }, [streams, chapter, loadCount]);

  /**
   * speaker switching
   */
  useEffect(() => {
    if (speakerIndex === undefined) {
      console.log('reset EVERYTHING'); // eslint-disable-line no-console
      // reset everything
      setIsPlaying(false);
      setTime(0);
      setTimeOffset(0);
      setCurrentStream(undefined);
      return;
    }
    if (streams && streams[speakerIndex] && streams[speakerIndex][chapter]) {
      setCurrentStream(streams[speakerIndex][chapter]);
    }
  }, [speakerIndex, streams, chapter]);

  /**
   * chapter switching
   */
  useEffect(() => {
    if (chapter) {
      setTime(0);
      if (chapter > INITIAL_CHUNK) {
        setTimeOffset((prevTimeOffset) => prevTimeOffset + chapterInfo[chapter - 1].duration);
      }
    }
  }, [chapter]);

  /**
   * Buffering
   */
  useEffect(() => {
    if (currentStream) {
      let t = setInterval(() => {
        if (currentStream.isLoaded()) {
          setIsBuffering(false);
          console.log(currentStream.name, 'buffer loaded');
          clearInterval(t);
          t = undefined;
        } else {
          setIsBuffering(true);
          console.log(currentStream.name, 'buffering...');
        }
      }, 200);
      return () => {
        if (t) {
          clearInterval(t);
        }
      };
    }
    return null;
  }, [currentStream]);


  useEffect(() => {
    if (!speakers[speakerIndex]) {
      setDisplayName('');
      return undefined;
    }
    setTextOpacity(0);
    const t = setTimeout(() => {
      setTextOpacity(1);
      setDisplayName(speakers[speakerIndex].title);
    }, 1000);

    return () => {
      clearTimeout(t);
    };
  }, [speakerIndex]);


  /**
   * handle player control
   */
  useEffect(() => {
    if (streams === undefined || speakerIndex === undefined) return undefined;
    let t;
    if (isPlaying) {
      currentStream.play(time);
      t = setInterval(() => {
        setTime(currentStream.getTime());
      }, 100);

      return () => {
        currentStream.pause();
        if (t) {
          clearInterval(t);
        }
      };
    } else {
      currentStream.pause();
    }
    return undefined;
  }, [isPlaying, currentStream]);

  // return <TimeDisplay time={time} />;

  return (
    <>
      <svg
        width={canvasWidth}
        height={canvasHeight}
        className={styles.logo}
        viewBox={viewBoxData}
      >
        <circle cx={mainCircleX} cy={mainCircleY} r={mainRadius} />
        <polyline points={pointsTriangle} />
        <polyline points={pointsLine} />
        {
          circleData.map(({ x, y, r, className }, index) => {
            if (index === (circleData.length - 1)) {
              return (
                <a
                  key={`${x},${y}`}
                  href="https://theignition.net/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <circle
                    cx={x}
                    cy={y}
                    r={r}
                    className={styles.circle}
                  />
                </a>
              );
            }
            return (
              <circle
                role="button"
                key={`${x},${y}`}
                tabIndex="0"
                cx={x}
                cy={y}
                r={r}
                className={speakerIndex === index ? styles.circleSelected : styles.circleSelectable}
                onClick={() => {
                  setSpeakerIndex(index);
                }}
                onKeyDown={(e) => {
                  // spacebar or return
                  if (e.keyCode === 32 || e.keyCode === 13) {
                    e.preventDefault();
                    setSpeakerIndex(index);
                  }
                }}
              />
            );
          })
        }
        {/* <text x="50%" y="45%" style={{ opacity: textOpacity }}>*/}
        {/*  {displayName}*/}
        {/* </text>*/}
        <text x="50%" y="50%" style={{ opacity: speakerIndex === undefined ? 0 : 1 }}>
          {/* {chapter ? chapterInfo[chapter].name : undefined}*/}
          <tspan x="50%" y="55%" className={styles.buffering}>
            {isBuffering ? 'buffering...' : formatTime(time + timeOffset)}
          </tspan>
        </text>
        <g
          className={styles.controls}
          role="button"
          tabIndex="0"
          onClick={() => {
            setIsPlaying((prev) => !prev);
          }}
          onKeyDown={(e) => {
            // spacebar or return
            if (e.keyCode === 32 || e.keyCode === 13) {
              e.preventDefault();
              setIsPlaying((prev) => !prev);
            }
          }}
          style={{ opacity: speakerIndex === undefined ? 0 : 1 }}
        >
          <rect x={230} y="55%" width={40} height={40} rx={5} ry={5} />
          <g className={styles.controlIcon}>
            {
              isPlaying
                ? (
                  <g>
                    <rect x={pauseBar1X} y={pauseBar1Y} width={5} height={25} rx={2} ry={2} />
                    <rect x={pauseBar2X} y={pauseBar1Y} width={5} height={25} rx={2} ry={2} />
                  </g>
                )
                : <polygon points={`${playPoint1} ${playPoint2} ${playPoint3} ${playPoint1}`} />

            }
          </g>
        </g>
        Sorry, your browser does not support inline SVG.
      </svg>
      {
        __DEVELOPMENT__ ? (
          <>
            <TimeDisplay time={time} />
            <TimeDisplay time={time + timeOffset} />
          </>
        ) : undefined
      }
    </>
  );
};

Player.propTypes = {
  speakers: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    track: PropTypes.string
  }))
};

Player.defaultProps = {
  speakers: []
};

export {
  Player
};
